<template>
	<article-wrapper
			:progressColor="`#0096D6`">

			<template v-slot:hero>
				<hero class="csr__hero">
					<figure>Diversity & inclusion</figure>
					<h1>The Impact <br>of  Allyship  </h1>
					<p>It’s important to recognize that our goal at DTCC is to create an organization rooted in parity and fairness and where all employees can actively contribute to the company’s success while feeling valued and respected. This is a key element of achieving our objectives.</p>
				</hero>
			</template>

			<template v-slot:body>
				<div class="csr__body">
					<div class="body-columns--small">
						<p>Our goal at DTCC is to create an organization rooted in parity and fairness and create an atmosphere where all employees can actively contribute to the company’s success while feeling valued and respected. This is a key element of achieving our strategic business objectives.</p>

						<p>We designated 2020 as the “Year of Allies and Sponsors” to underscore the importance of strengthening our culture of inclusion and to promote our ongoing journey of equity and diversity in the workplace. </p>
					</div>
				</div>

				<div class="body-quote body-quote--1">
					<div class="body-quote__before"></div>
					<div class="body-quote__content body-quote__content--right">
						<div class="body-quote__quote">
							“We are proud of our allies and supporters who are actively involved working to make meaningful progress on Diversity & Inclusion. Just one person can make a real difference. Valuing individual’s unique perspectives will always make them a valuable part of any team and will make our culture of allyship stronger.”
							<div class="body-quote__credit">— <strong>Keisha Bell</strong>,  Managing Director, Diverse Talent Management & Advancement </div>
						</div>
					</div>
					<div class="body-quote__after"></div>
				</div>

				<div class="csr__body">
					<div class="body-columns">
						<div class="body-columns__content">
							<h2 style="margin-top: 0;">Advocating for <br><span>Our Employees</span></h2>
							<p>Our continued commitment to this mission requires everyone’s active participation as collaborators, partners and upstanders – all of which are equally essential to driving sustained, systemic improvements to our workplace culture, policies and practices.</p>

							<p>We were intentional in expanding our ally and sponsor culture in 2020 by enlisting the support of our most senior executives so they could use their influence and leadership status to advocate for the advancement of individuals who belonged to one or more diverse groups. Notwithstanding others, this is especially important for gender equity.</p>
						</div>

						<div class="body-columns__images">
							<div class="body-box">
								<h3><img src="~@/assets/csr/allyshipIcon1.svg">Advancing Women Leaders</h3>
								<p>When women have sponsors, it can narrow the advancement gap between women and men. What’s more, sponsors actively support talented protégés with backgrounds different from their own and become their vocal advocates.  </p>
							</div>
							<div class="body-box">
								<h3><img src="~@/assets/csr/allyshipIcon2.svg">Investing in Protégés</h3>
								<p>They also invest significant social and political capital to have their protégés nominated for opportunities on the basis of their high potential, without the expectation from them to prove they can do a job in advance. This practice is now more transparent – it was previously in place but unsaid. Finally, allies and sponsors expose people to their own professional networks and to opportunities that otherwise would remain inaccessible.</p>
							</div>
						</div>
					</div>
				</div>

				<div class="body-quote body-quote--2">
					<div class="body-quote__before"></div>
					<div class="body-quote__content body-quote__content--right">
						<div class="body-quote__quote">
							Diversity, inclusion and representation power the best organizations and I am proud to be part of a company that is making such positive progress in recruiting diverse talent. Not only is diverse recruitment the right thing to do, it is the smart thing to do. Different backgrounds and perspectives lead to a variety of ideas, knowledge and ways of doing things. We are building a reputation for valuing differences and working to attract talented employees that feel they will be appreciated and able to utilize the skills they bring to the&nbsp;table.
							<div class="body-quote__credit">— <strong>Raina Rene</strong>,  Senior Associate and Co-Chair, BOLD Employee Resource Group  </div>
						</div>
					</div>
					<div class="body-quote__after"></div>
				</div>

				<div class="csr__body">

					<div class="body-columns--small">
						<h2>Committed to <span>Allyship</span></h2>
						<p>When we focused on allyship in 2020, no one could have ever imagined how relevant it would become and the profound impact it would have in influencing our inclusive workplace. Many of our teams across the globe committed to change, actively participated, opened doors of opportunity, put themselves on the hook and held each other accountable. It was a year in which people worked together and supported each other in a truly sincere manner.   </p>

						<p>The 2020 events of pandemic and social injustice profoundly impacted our staff, sparking true allyship among so many people across our organization. Many spoke up, expressed empathy, gathered courage and had conversations that otherwise would not have taken place. We, in turn, provided them with the opportunity to share their feelings and experiences in open forums through targeted sessions.  </p>

						<p>As we progress into 2021, a key priority is to strengthen our allyship culture. Our 2021 theme will move us from ‘being’ allies to ‘becoming’ upstanders who will continue to foster a truly diverse and inclusive workplace. </p>
					</div>

				</div>

			</template>

			<template v-slot:footer>
				<article-next class="csr__next" :image="'csr/bgStem@2x.jpg'" :link="{name: 'csr', params: {
					article: 'stem'}}" target-id="next_article" v-hero-click="`#next_article`">
					<template v-slot:label>Corporate Social Responsibility</template>
					<template v-slot:title>Advancing  STEM Education  Through Our Rising Stars</template>
				</article-next>
			</template>

		</article-wrapper>
</template>

<script>
import ArticleNext from '@/components/ArticleNext'
import ArticleWrapper from '@/components/ArticleWrapper'
import Hero from '@/components/Hero'
import Boxes from '@/components/Boxes'

export default {
	name: 'Allyship',
	components: {
		ArticleNext,
		ArticleWrapper,
		Hero,
		Boxes
	},
}
</script>

<style scoped lang="scss">

.csr {

	&__hero {
		background-image: url('~@/assets/csr/bgAllyship@2x.jpg');
	}

	.body-quote {

		&--1 {
			background-image: url('~@/assets/csr/allyshipQuote1@2x.jpg');
		}

		&--2 {
			background-image: url('~@/assets/csr/allyshipQuote2@2x.jpg');
		}

	}

	.body-box {
		@include media-query-min-width('tablet') {
			width: 340px;
		}

		@include media-query-min-width('desktop') {
			width: 450px;
		}
	}
}

</style>
